import { JSV } from "third_party_libraries/jsv/jsv";

// FIXME: We should store everything as JSV from the beginning
function fix(obj) {
  return Object.keys(obj)
    .map(key => `${key}=${JSV.stringify(obj[key])}`)
    .join("&");
}

const _lookupMap = {
  shared: {
    contactlabel: `lo={t:ContactLabel}`,
    lookupclients: `lo={t:Client,OrganizationIdCondition:Bypass,State:Active}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    lookupemployees: `lo={t:Employee,OrganizationIdCondition:Matches,State:Active}&lt={Options:0,OriginalType:employee,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    lookupproviders: `lo={t:Provider,OrganizationIdCondition:Bypass,State:Active}&lt={Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    lookupproviders_alpha: `alphaSort=true&lo={t:Provider,OrganizationIdCondition:Bypass,State:Active}&lt={Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    lookupservicecodes: `lo={t:ServiceCode,OrganizationIdCondition:Default,State:Active}`,
    insurancecompanies: `lo={t:InsuranceCompany}`
  },
  billingmanager: {
    billingentries: `lo={t:BillingLabel},{t:ContactLabel},{t:CodeLabel},{t:TaskLabel},{t:PaymentReference},{t:Insurance},{t:ServiceCode},{t:ServiceLocationId},{t:Employee},{t:Client},{t:Invoice},{t:BillingEntry},{t:Claim}&lt={Options:2,OriginalType:paymentreference,TargetTypes:[paymentreference]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    authorizations: `lo={t:Client},{t:Employee},{t:ContactLabel},{t:CodeLabel},{t:TaskLabel},{t:Insurance},{t:ServiceCode}&lt={Options:0,OriginalType:provider,TargetTypes:[manager,implementer,principal,billing,referring,providerSupplier,ordering,supervising,facility]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    authorizationspayor: `lo={t:Client,OrganizationIdCondition:Bypass,State:Active},{t:ContactLabel,State:Active}`,
    servicecodes: `lo={t:Client},{t:Employee},{t:CodeLabel},{t:ServiceCode},{t:FeeSchedule}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    defaultrates: `lo={t:Client},{t:Employee},{t:CodeLabel},{t:ServiceCode}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    customrates: `lo={t:Client},{t:Employee},{t:CodeLabel},{t:ServiceCode}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    driverates: `lo={t:Client},{t:Employee},{t:ContactLabel}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    paymentrecon: `lo={t:Client},{t:Employee},{t:CodeLabel},{t:BillingLabel},{t:Insurance},{t:BillingEntry},{t:ServiceCode},{t:PaymentReference}&lt={Options:1,OriginalType:billinglabel,TargetTypes:[paymentLabel]},{Options:0,OriginalType:email,TargetTypes:[]},{Options:2,OriginalType:paymentreference,TargetTypes:[paymentreference]}`,
    receivables: `lo={t:Client},{t:Employee},{t:CodeLabel},{t:ContactLabel},{t:BillingLabel},{t:Insurance}`,
    transactions: fix({
      lookupOptions: [{ lookupType: "ReconTransaction" }, { lookupType: "BillingLabel" }, { lookupType: "PaymentReference" }],
      lookupTransforms: { options: 2, originalType: "paymentReference", targetTypes: ["paymentReference"] }
    }),
    payments: `lo={t:ContactLabel},{t:BillingLabel},{t:CodeLabel},{t:Client},{t:Provider},{t:Insurance},{t:PaymentReference}&lt={options:1,originalType:provider,targetTypes:[appliedBy]},{options:2,originalType:paymentreference,targetTypes:[paymentreference]},{options:Email,originalType:email,targetTypes:[]}`,
    sales: `lo={t:Client},{t:Employee},{t:ContactLabel},{t:CodeLabel},{t:Insurance},{t:ServiceCode}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    clients: `lo={t:Client,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    clientsAndProviders: `lo={t:Provider,State:Active},{t:Client,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    clientsProvidersServiceCodesContactLabelsEventLabels: `lo={t:Provider,State:Active},{t:Client,State:Active},{t:CodeLabel},{t:ServiceCode},{t:ContactLabel},{t:ScheduleEventLabel}&lt={Options:0,OriginalType:email,TargetTypes:[]},{Options:0,OriginalType:email,TargetTypes:[]},{Options:0,originalType:scheduleeventlabel,targetTypes:[eventLabel]}`,
    notetemplateservicecodes: `lo={t:ServiceCode}`,
    accrued: `lo={t:Client},{t:Insurance},{t:ServiceCode}&lt={Options:8,OriginalType:insurance,TargetTypes:[]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    invoices: `lo={t:Invoice},{t:BillingEntry},{t:ContactLabel},{t:Client}&lt={Options:0,OriginalType:email,TargetTypes:[]}`
  },
  bundles: {
    sessions: `lo={t:AllContacts,OrganizationIdCondition:Bypass},{t:ContactLabel,OrganizationIdCondition:Default}&lt={Options:0,OriginalType:client,TargetTypes:[assignedTo,author]},{Options:0,OriginalType:provider,TargetTypes:[assignedTo,author]},{Options:0,OriginalType:email,TargetTypes:[assignedTo,author]}`,
    goaldashboard: `lo={t:Client},{t:Employee},{t:ContactLabel}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    dashboard: `lo={t:Client,State:Active},{t:Provider,State:Active},{t:ContactLabel},{t:BundleLabel}&lt={Options:0,OriginalType:client,TargetTypes:[assignedTo,createdBy]},{Options:0,OriginalType:provider,TargetTypes:[assignedTo,createdBy]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    vbmapps: `lo={t:Client},{t:ContactLabel}`,
    routines: `lo={t:Client}`,
    coursegroupseats: `lo={t:Employee,State:Active},{t:Generics,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    sessionshare: `lo={t:Employee,State:Active},{t:Provider,OrganizationIdCondition:Bypass},{t:ContactLabel}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    lookupgoalslist: fix({
      lookupOptions: [{ lookupType: "Client" }, { lookupType: "ContactLabel" }, { lookupType: "BundleLabel" }],
      lookupTransforms: [{ Options: 0, OriginalType: "email", TargetTypes: [] }]
    }),
    assignclients: `lo={t:Client,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    assignproviders: `lo={t:Employee,State:Active},{t:Generics,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    assign: `lo={t:AllContacts,OrganizationIdCondition:Bypass,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    bulkshare: `lo={t:Employee,State:Active},{t:Generics,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    bundlelabel: `lo={t:BundleLabel}`
  },
  reporting: {
    report370: `lo={t:CodeLabel}`,
    report370more: `lo={t:ContactLabel}`,
    report113: `lo={t:Client},{t:Employee},{t:ContactLabel},{t:CodeLabel},{t:BillingLabel},{t:ServiceCode}`,
    report116: `lo={t:ContactLabel}`,
    supervision: fix({
      lookupOptions: [{ lookupType: "ContactLabel" }, { lookupType: "Insurance" }, { lookupType: "Employee" }, { lookupType: "Client" }],
      lookupTransforms: [{ Options: 0, OriginalType: "email", TargetTypes: [] }]
    }),
    productivity: fix({
      lookupOptions: [
        { lookupType: "Client" },
        { lookupType: "CodeLabel" },
        { lookupType: "ContactLabel" },
        { lookupType: "Employee" },
        { lookupType: "ContactLabel" },
        { lookupType: "Provider" },
        { lookupType: "Insurance" },
        { lookupType: "ServiceCode" },
        { lookupType: "BillingLabel" },
        { lookupType: "BillingEntry" }
      ],
      lookupTransforms: [{ Options: 0, OriginalType: "email", TargetTypes: [] }]
    }),
    rcm: fix({
      lookupOptions: [
        { lookupType: "Client" },
        { lookupType: "Employee" },
        { lookupType: "ContactLabel" },
        { lookupType: "CodeLabel" },
        { lookupType: "Insurance" },
        { lookupType: "ServiceCode" },
        { lookupType: "BillingLabel" }
      ],
      lookupTransforms: [{ Options: 0, OriginalType: "email", TargetTypes: [] }]
    }),
    receivables: fix({
      lookupOptions: [
        { lookupType: "Client" },
        { lookupType: "Employee" },
        { lookupType: "ContactLabel" },
        { lookupType: "CodeLabel" },
        { lookupType: "Insurance" },
        { lookupType: "ServiceCode" },
        { lookupType: "BillingLabel" }
      ],
      lookupTransforms: [{ Options: 0, OriginalType: "email", TargetTypes: [] }]
    })
  },
  scheduling: {
    search: fix({
      lookupOptions: [
        { lookupType: "ScheduleContact", State: "Active", OrganizationIdCondition: "Default" },
        { lookupType: "ContactLabel" },
        { lookupType: "ServiceCode" },
        { lookupType: "Authorization" },
        { lookupType: "Insurance" },
        { lookupType: "Attendance" },
        { lookupType: "CodeLabel" },
        { lookupType: "ScheduleEventLabel" }
      ],
      lookupTransforms: [
        { Options: 0, OriginalType: "scheduleeventlabel", TargetTypes: ["eventLabel"] },
        { Options: 4, OriginalType: "provider", TargetTypes: ["principal1", "contact"] },
        { Options: 0, OriginalType: "client", TargetTypes: ["principal2", "contact"] },
        { Options: 0, OriginalType: "servicecode", TargetTypes: ["billingcode"] },
        { Options: 0, OriginalType: "insurance", TargetTypes: ["payorCompany"] },
        { Options: 0, OriginalType: "email", TargetTypes: [] }
      ]
    }),
    list: fix({
      lookupOptions: [
        { lookupType: "ScheduleContact", State: "Active", OrganizationIdCondition: "Default" },
        { lookupType: "ContactLabel" },
        { lookupType: "ServiceCode" },
        { lookupType: "Authorization" },
        { lookupType: "Insurance" },
        { lookupType: "Attendance" },
        { lookupType: "Authorization" },
        { lookupType: "CodeLabel" },
        { lookupType: "ScheduleEventLabel" },
        { lookupType: "Authorization" }
      ],
      lookupTransforms: [
        {
          Options: 0,
          OriginalType: "scheduleeventlabel",
          TargetTypes: ["eventLabel"]
        },
        {
          Options: 4,
          OriginalType: "provider",
          TargetTypes: ["principal1", "principal2", "contact"]
        },
        {
          Options: 0,
          OriginalType: "client",
          TargetTypes: ["principal2", "contact"]
        },
        {
          Options: 0,
          OriginalType: "servicecode",
          TargetTypes: ["billingcode"]
        },
        {
          Options: 0,
          OriginalType: "insurance",
          TargetTypes: ["payorCompany"]
        },
        {
          Options: 0,
          OriginalType: "email",
          TargetTypes: []
        }
      ]
    })
  },
  contacts: {
    principals: fix({
      lookupOptions: [{ lookupType: "Employee", OrganizationIdCondition: "Bypass" }],
      lookupTransforms: [
        { Options: 0, OriginalType: "provider", TargetTypes: ["contact"] },
        { Options: 0, OriginalType: "id", TargetTypes: ["contact"] },
        { Options: 0, OriginalType: "fullname", TargetTypes: ["contact"] },
        { Options: 0, OriginalType: "employee", TargetTypes: ["contact"] },
        { Options: 0, OriginalType: "email", TargetTypes: [] }
      ]
    }),
    providersforcontact: `lo={t:Provider,OrganizationIdCondition:Bypass}&lt={Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:employee,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    list: `lo={t:AllContacts},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listeveryoneall: `lo={t:AllContacts},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listeveryone: `lo={t:AllContacts,State:Active},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listeveryoneinactive: `lo={t:AllContacts,State:Inactive},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listclientsall: `lo={t:Client},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listclients: `lo={t:Client,State:Active},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listclientsinactive: `lo={t:Client,State:Inactive},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listemployeesall: `lo={t:Employee},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listemployees: `lo={t:Employee,State:Active},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listemployeesinactive: `lo={t:Employee,State:Inactive},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listgenericsall: `lo={t:Generics},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listgenerics: `lo={t:Generics,State:Active},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    listgenericsinactive: `lo={t:Generics,State:Inactive},{t:ContactLabel},{t:Form},{t:DiagnosisCodes}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`,
    addresses: `lo={t:AllContacts},{t:ContactLabel},&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:employee,TargetTypes:[contact]},{Options:2,OriginalType:email,TargetTypes:[email]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]}`
  },
  resources: {
    lookupassociations: `lo={t:Resource}`,
    providers: `lo={t:Provider,OrganizationIdCondition:Matches,State:Active}&lt={Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:employee,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    contacts: `lo={t:AllContacts,OrganizationIdCondition:Bypass,State:Active}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:employee,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    clients: `lo={t:Client,OrganizationIdCondition:Bypass,State:Active}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    contactsandlabels: `lo={t:Provider,OrganizationIdCondition:Bypass,State:Active},{t:ContactLabel}&lt={Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:employee,TargetTypes:[contact]}`,
    contactlabels: `lo={t:ContactLabel}`,
    templatesreport: `lo={t:AllContacts,OrganizationIdCondition:Bypass},{t:ContactLabel}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:generic,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`,
    lookupallresourcessearch: fix({
      lookupOptions: [
        { lookupType: "Client" },
        { lookupType: "Provider" },
        { lookupType: "ResourceLabel" },
        { lookupType: "ContactLabel" },
        { lookupType: "Claim" },
        { lookupType: "Resource", ResourceStatusFilter: "All" },
        { lookupType: "TemplateId" },
        { lookupType: "TemplateTypeId" },
        { lookupType: "TemplateGroupId" },
        { lookupType: "BillingEntry", IsResourceLookup: true }
      ],
      lookupTransforms: [
        {
          OriginalType: "provider",
          TargetTypes: ["contact", "signedbycontact", "unsignedbycontact", "createdbycontact"]
        },
        {
          OriginalType: "client",
          TargetTypes: ["contact", "signedbycontact", "unsignedbycontact", "createdbycontact"]
        }
      ]
    }),
    lookupactiveresourcessearch: fix({
      lookupOptions: [
        { lookupType: "Client" },
        { lookupType: "Provider" },
        { lookupType: "ResourceLabel" },
        { lookupType: "ContactLabel" },
        { lookupType: "Claim" },
        { lookupType: "Resource", ResourceStatusFilter: "Active" },
        { lookupType: "TemplateId" },
        { lookupType: "TemplateTypeId" },
        { lookupType: "TemplateGroupId" },
        { lookupType: "BillingEntry", IsResourceLookup: true }
      ],
      lookupTransforms: [
        {
          OriginalType: "provider",
          TargetTypes: ["contact", "signedbycontact", "unsignedbycontact", "createdbycontact"]
        },
        {
          OriginalType: "client",
          TargetTypes: ["contact", "signedbycontact", "unsignedbycontact", "createdbycontact"]
        }
      ]
    }),
    lookuparchivedresourcessearch: fix({
      lookupOptions: [
        { lookupType: "Client" },
        { lookupType: "Provider" },
        { lookupType: "ResourceLabel" },
        { lookupType: "ContactLabel" },
        { lookupType: "Claim" },
        { lookupType: "Resource", ResourceStatusFilter: "Archived" },
        { lookupType: "TemplateId" },
        { lookupType: "TemplateTypeId" },
        { lookupType: "TemplateGroupId" },
        { lookupType: "BillingEntry", IsResourceLookup: true }
      ],
      lookupTransforms: [
        {
          OriginalType: "provider",
          TargetTypes: ["contact", "signedbycontact", "unsignedbycontact", "createdbycontact"]
        },
        {
          OriginalType: "client",
          TargetTypes: ["contact", "signedbycontact", "unsignedbycontact", "createdbycontact"]
        }
      ]
    })
  },
  tasks: {
    contacts: `lo={t:AllContacts,OrganizationIdCondition:Bypass,State:Active},{t:ContactLabel}&lt={Options:0,OriginalType:email,TargetTypes:[contact]},{Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:employee,TargetTypes:[contact]}`,
    tasks: `lo={t:AllContacts},{t:TaskLabel}&lt={Options:,OriginalType:provider,TargetTypes:[createdBy,assignedTo,completedBy,deletedBy,onBehalfOf,ticketsFromOrg]},{Options:,OriginalType:client,TargetTypes:[createdBy,assignedTo,completedBy,deletedBy,onBehalfOf,ticketsFromOrg]},{Options:0,OriginalType:email,TargetTypes:[]`,
    taskscr: `lo={t:AllContacts},{t:TaskLabel},{t:ContactLabel}&lt={Options:,OriginalType:provider,TargetTypes:[createdBy,assignedTo,completedBy,deletedBy,onBehalfOf,ticketsFromOrg]},{Options:,OriginalType:client,TargetTypes:[createdBy,assignedTo,completedBy,deletedBy,onBehalfOf,ticketsFromOrg]},{Options:0,OriginalType:email,TargetTypes:[]`,
    servicecodes: `lo={t:ServiceCode}`
  },
  claims: {
    provider: `lo={t:Employee,State:Active},{t:Generics,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`
    //'provider': `lo={t:Employee,State:Active},{t:Generics,State:Active}&lt={Options:0,OriginalType:employee,TargetTypes:[contact]},{Options:0,OriginalType:provider,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`
  },
  claimsera: {
    lookupclients: `lo={t:Client,OrganizationIdCondition:Bypass,State:All}&lt={Options:0,OriginalType:client,TargetTypes:[contact]},{Options:0,OriginalType:id,TargetTypes:[contact]},{Options:0,OriginalType:fullname,TargetTypes:[contact]},{Options:0,OriginalType:email,TargetTypes:[]}`
  },
  notes: {
    templates: `lo={t:NoteLabel}`
  },
  permissions: {
    search: `lo={t:ContactLabel}`
  },
  permissiongroups: {
    search: `lo={t:PermissionGroup}`
  },
  payroll: {
    employeelist: `lo={t:Employee,State:Active},{t:ContactLabel}&lt={Options:0,OriginalType:email,TargetTypes:[]}`,
    ptorequests: `lo={t:Employee,State:Active},{t:ContactLabel}&lt={Options:0,OriginalType:provider,TargetTypes:[contact]}`,
    benefitgroupemployees: `lo={t:Employee,State:Active}`,
    benefitgroupemployeeswithlabels: `lo={t:Employee,State:Active},{t:ContactLabel}`
  },
  messaging: {
    list: `lo={t:MessageLabel},{t:AllContacts,State:Active,OrganizationIdCondition:Default}&lt={Options:0,OriginalType:client,TargetTypes:[sentBy,sentTo]},{Options:0,OriginalType:provider,TargetTypes:[sentBy,sentTo]},{Options:0,OriginalType:email,TargetTypes:[]}`,

    // Chat with clients DENIED
    chat: `lo={t:Employee,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`
    // Chat with clients ACTIVE
    // 'chat': `lo={t:Employee,State:Active},{t:Client,State:Active}&lt={Options:0,OriginalType:email,TargetTypes:[]}`
  },
  assets: {
    list: `lo={t:Client,State:Active},{t:Employee,State:Active},{t:ContactLabel},{t:AssetLabel}&lt={Options:0,originalType:client,TargetTypes:[assignedTo]},{Options:0,originalType:provider,TargetTypes:[assignedTo,purchasedBy]},{Options:0,OriginalType:email,TargetTypes:[]},{Options:0,OriginalType:contactLabel,TargetTypes:[assignedToLabel]}`
  },
  manage: {
    organizations: `lo={t:Organization,State:Active}`
  }
};

export default class ElasticLookupMapper {
  constructor() {}
  static mapMidTierToApi(source) {
    return {
      source: _lookupMap[source.split(".")[0]][source.split(".")[1]]
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/\s+/g, "")
        .replace(/lo=/g, "lookupOptions=")
        .replace(/lt=/g, "lookupTransforms=")
        .replace(/{t:/g, "{LookupType:"),
      list: "results",
      id: "id",
      display: "displayName"
    };
  }

  // check if we have a specific action already mapped to ES
  // actions are made up of channel.action, so "billingmanager.billingentries" would be the call from the billing/timesheets grid
  static hasElasticEquivalent(source) {
    try {
      return !!_lookupMap[source.split(".")[0]][source.split(".")[1]];
    } catch (ex) {
      return false;
    }
  }
}
